<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h1>Movimientos de Inventario</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="2">
          <b-button variant="success" @click="$bvModal.show('modal-newStockPicking')">Crear Movimiento</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table striped hover :items="pickings" :fields="pickingsLabels" responsive> <!--:fields="pickingsLabels"-->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusBadge(data.item.status).variant">{{ statusBadge(data.item.status).text
                }}</b-badge>
            </template>
            <template #cell(buttons)="data">
              <b-button size="sm" variant="success" @click="$router.push('/movimiento/' + data.item.id)" class="mr-1">
                +Info
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-modal id="modal-newStockPicking" :hide-footer="true" size="xl" title="Crear Movimiento de Inventario">
        <b-row>
          <b-col lg="12" cols="12">
            <b-form-group label="Tipo de Movimiento" description="Selecciona el tipo de movimiento">
              <vu-select @input="modifiedPickingType" v-model.number="newStockPicking.picking_type_id"
                :options="pickingTypes" :reduce="(x) => x.id" label="barcode"></vu-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-show="newStockPicking.picking_type_id>0">
          <b-col lg="6" cols="12">
            <b-form-group label="De" description="Selecciona la ubicacion origen">
              <vu-select :disabled="newStockPicking.locked_src_location" v-model.number="newStockPicking.location_id"
                :options="locations" :reduce="(x) => x.id" label="complete_name"></vu-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" cols="12">
            <b-form-group label="Hacia" description="Selecciona la ubicacion destino">
              <vu-select :disabled="newStockPicking.locked_dst_location"
                v-model.number="newStockPicking.location_dest_id" :options="locations" :reduce="(x) => x.id"
                label="complete_name"></vu-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6">
            <b-form-group label="Nombre" description="Nombre para identificar el movimiento">
              <b-form-input v-model="newStockPicking.picking_name" type="text" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="6">
            <b-form-group label="Nota" description="Notas del movimiento">
              <b-form-input v-model="newStockPicking.note" type="text" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group label="Fecha Esperada:" description="Fecha en la que esperas realizar el movimiento">
              <b-form-input v-model="newStockPicking.scheduled_date" type="date" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group label="Fecha Maxima:" description="Fecha en la que debe estar el movimiento">
              <b-form-input v-model="newStockPicking.date_deadline" type="date" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="success" @click="createPicking">Crear</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>

  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import router from "../router"
import moment from 'moment';
export default {
  name: "Movimientos",
  components: {
    Nav
  },

  data() {
    return {
      newStockPicking: {
        picking_type_id: null,
        location_id: null,
        location_dest_id: null,

        picking_name: "",
        note: "",

        scheduled_date: "",
        date_deadline: "",

        move_type: "direct",
        locked_src_location: false,
        locked_dst_location: false
      },
      initialDate: "",
      lastDate: "",
      allSucsArray: [],
      pickingTypes: [],
      locations: [],
      search: "",
      pickings: [],
      actualSucursal: {
        id: null
      },
      pickingsLabels: [
        { key: "id", label: "ID" },
        { key: "picking_name", label: "Referencia" },
        { key: "src_location.complete_name", label: "Desde" },
        { key: "dst_location.complete_name", label: "Hacia" },
        { key: "picking_type.name", label: "Operacion" },
        { key: "scheduled_date", label: "Fecha Planeada" },
        { key: "create_date", label: "Fecha de creacion" },
        { key: "state", label: "Status" },
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    this.getMovimientos()
    this.getPickingTypes()
    this.getLocations()
  },
  created() {

  },
  methods: {
    modifiedPickingType() {
      console.log("modifiedPickingType")
      let pt = this.pickingTypes.find((x) => x.id == this.newStockPicking.picking_type_id)
      if (pt.default_src_location_id != null) {
        this.newStockPicking.locked_src_location = true
        this.newStockPicking.location_id = pt.default_src_location_id
      } else {
        this.newStockPicking.locked_src_location = false
        this.newStockPicking.location_id = null
      }
      if (pt.default_dst_location_id != null) {
        this.newStockPicking.locked_dst_location = true
        this.newStockPicking.location_dest_id = pt.default_dst_location_id
      } else {
        this.newStockPicking.locked_dst_location = false
        this.newStockPicking.location_dest_id = null
      }
    },
    createPicking(){
      let that=this
      axios.post("/stockpicking",this.newStockPicking)
        .then(function (res) {
          router.push('/movimiento/' + res.data.id)
      this.getCompras()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getPickingTypes() {
      let that = this
      axios.get("/stockpicking/types")
        .then(function (res) {
          that.pickingTypes = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getLocations() {
      let that = this
      axios.get("/stocklocations")
        .then(function (res) {
          that.locations = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    searchRecv(p) {
      this.initialDate = moment(p.initDate).format('YYYY-MM-DD');
      this.lastDate = moment(p.endDate).format('YYYY-MM-DD');
      this.allSucsArray = p.sucursales
      this.getMovimientos()
    },
    newPOError(evt) {
      console.log("error reading")
      console.log(evt)
      this.getMovimientos()
      this.makeToast("danger", "Error", evt.data)
    },
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "draft":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "sent":
          rtn.variant = "warning"
          rtn.text = "Enviado"
          break;
        case "done":
          rtn.variant = "success"
          rtn.text = "Enviado"
          break;
        case "canceled":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    newPOCreated(evt) {
      console.log(evt)
      router.push('/movimiento/' + evt)
      this.getMovimientos()
    },
    getMovimientos() {
      let that = this
      axios.get("/stockpickings")
        .then(function (res) {
          that.pickings = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        okVariant: variant,
        centered: true
      })
      // this.$bvToast.toast(text, {
      //   title: title,
      //   variant: variant,
      //   solid: true,
      //   appendToast: true,
      // });
    },
  },
};
</script>
