<template>
  <div>
    <b-row class="mb-3">
      <b-col lg="4" md="6" xs="12">
        <b-form-datepicker @input="inputChanged" v-model="initialDate" class="mb-2"></b-form-datepicker>
      </b-col>
      <b-col lg="4" md="6" xs="12">
        <b-form-datepicker @input="inputChanged" v-model="lastDate" :min="minLastDate" class="mb-2"></b-form-datepicker>
      </b-col>
      <b-col lg="4" md="6" xs="12">
        <b-button class="mb-2" variant="default" v-b-modal.modal-sucursales block>Sucursales: {{ sucursales.length }} Seleccionadas</b-button>
      </b-col>
      <b-col lg="12" md="6" xs="12">
        <b-button variant="success" @click="changeDate" block>Buscar</b-button>
      </b-col>
    </b-row>
    <b-modal ok-only id="modal-sucursales" @ok="inputChanged" title="Sucursales">
      <b-row>
        <b-col>
          <b-button-group>
            <b-button variant="success" @click="selectAllSucursales()">Seleccionar todo</b-button>
            <b-button variant="danger" @click="sucursales = []">Quitar seleccion</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-checkbox-group stacked v-model="sucursales" :options="sucOptions">
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';


export default {
  name: "ReportSearchBar",
  watch: {
    initialDate(i) {
      this.minLastDate=moment(i)
      // this.minLastDate = moment(i).add(1, 'days').format()
      if (moment(this.initialDate).isAfter(moment(this.lastDate))) {
        this.lastDate = null
      }
    }
  },
  props: {
    emitOnStart: {
      type:Boolean,
      default: true
    },
    emitOnChange: {
      type:Boolean,
      default: true
    },
  },
  data() {
    return {
      allSucsArray: [],
      initialDate: null,
      lastDate: null,
      minLastDate: null,
      sucOptions: [],
      sucursales: []
    };
  },
  mounted() {
    moment().locale("es-mx")
    this.initialDate = moment().subtract(7, "days").format()
    this.lastDate = moment().add(1, "days").format()
    this.minLastDate = moment(this.initialDate).add(1, 'days').format()
    this.getSucursals()
  },
  methods: {
    selectAllSucursales() {
      let that = this
      this.sucursales = []
      this.sucOptions.forEach((element) => {
        that.sucursales.push(element.value)
      })
    },
    getSucursals() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          let l=response.data.sort((a, b) => { return a.nombre.localeCompare(b.nombre) })
          l.forEach((element) => {
            that.sucOptions.push({ text: element.nombre, value: element.id });
            that.sucursales.push(element.id)
          });
          if(that.emitOnStart){
            that.changeDate()
          }
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    changeDate() {
      console.log("changeDate")
      this.$emit("search",{sucursales: this.sucursales,initDate: this.initialDate,endDate:this.lastDate})
    },
    inputChanged(){
      if(this.emitOnChange){
        this.changeDate()
      }
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  }
}
</script>