<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <ReportSearchBar :emitOnChange="false" @search="searchRecv"></ReportSearchBar>
      <b-row>
        <b-col>
          <h1>Compras</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="2">
          <b-button variant="success" @click="$bvModal.show('modal-newPurchaseOrder')">Crear Compra</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table :filter="search" striped hover :items="compras" :fields="comprasLabels" responsive>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusBadge(data.item.status).variant">{{ statusBadge(data.item.status).text
                }}</b-badge>
            </template>
            <template #cell(buttons)="data">
              <b-button size="sm" variant="success" @click="$router.push('/compra/' + data.item.id)" class="mr-1">
                +Info
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-modal id="modal-newPurchaseOrder" :hide-footer="true" size="xl" title="Crear Orden de Compra">
        <CreatePurchaseOrder @created="newPOCreated" @error="newPOError" >
        </CreatePurchaseOrder>
      </b-modal>
    </b-container>

  </div>
</template>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import router from "../router"
import ReportSearchBar from "./reports/searchBar/ReportSearchBar.vue";
import moment from 'moment';
import CreatePurchaseOrder from "../components/CreatePurchaseOrder.vue";
export default {
  name: "Compras",
  components: {
    Nav, ReportSearchBar, CreatePurchaseOrder
  },

  data() {
    return {
      initialDate: "",
      lastDate: "",
      allSucsArray: [],
      search: "",
      compras: [],
      actualSucursal: {
        id: null
      },
      comprasLabels: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nombre" },
        { key: "provider.name", label: "Proveedor" },
        { key: "user.name", label: "Creado Por" },
        { key: "sucursal.nombre", label: "Sucursal" },
        { key: "status", label: "Status" },
        { key: "date_create", label: "Creacion" },
        { key: "date_planned", label: "Entrega planeada" },
        { key: "buttons", label: "" }
      ]
    };
  },

  computed: {

  },
  watch: {},
  mounted() {

  },
  created() {

  },
  methods: {
    searchRecv(p) {
      this.initialDate = moment(p.initDate).format('YYYY-MM-DD');
      this.lastDate = moment(p.endDate).format('YYYY-MM-DD');
      this.allSucsArray = p.sucursales
      this.getCompras()
    },
    newPOError(evt) {
      console.log("error reading")
      console.log(evt)
      this.getCompras()
      this.makeToast("danger", "Error", evt.data)
    },
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "draft":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "sent":
          rtn.variant = "warning"
          rtn.text = "Enviado"
          break;
        case "confirmed":
          rtn.variant = "primary"
          rtn.text = "Confirmado"
          break;
        case "assigned":
          rtn.variant = "primary"
          rtn.text = "Asignado"
          break;
        case "done":
          rtn.variant = "success"
          rtn.text = "Hecho"
          break;
        
        case "canceled":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    newPOCreated(evt) {
      console.log(evt)
      router.push('/compra/' + evt)
      this.getCompras()
    },
    getCompras() {
      let that = this
      console.log("/purchaseorders?sucursales=" + this.allSucsArray.join(",") + "&init_date=" + this.initialDate + "&end_date=" + this.lastDate)
      axios.get("/purchaseorders?sucursales=" + this.allSucsArray.join(",") + "&init_date=" + this.initialDate + "&end_date=" + this.lastDate)
        .then(function (res) {
          that.compras = res.data
        }).catch(function (err) {
          console.error(err)
        })
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        okVariant: variant,
        centered: true
      })
      // this.$bvToast.toast(text, {
      //   title: title,
      //   variant: variant,
      //   solid: true,
      //   appendToast: true,
      // });
    },
  },
};
</script>
