<template>
  <div style="min-height: 70vh">
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Reportes por periodo de tiempo</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-input-group prepend="REPORTE" class="mb-2">
            <b-form-select v-model="actualReport" :options="reportOptions" :state="actualReport != ''">
            </b-form-select>
            <b-input-group-append>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <ReportSearchBar @search="changeDates"></ReportSearchBar>
    </b-container>
    <b-overlay :show="loadingReports" rounded="sm" spinner-type="grow">
      <b-card no-body>
        <div class="m-3" v-if="!loadingReports">
          <b-row>
            <b-col>
              <h3>Registros encontrados: {{ tableData.length }}</h3>
            </b-col>
            <b-col v-if="tableData.length > 0 && !loadingReports && !actualReportDownloadFileBack">
              <download-csv :data="tableData" class="btn btn-primary" :name="fileName + '.csv'"
                separator-excel>Descargar
              </download-csv>
            </b-col>
            <b-col v-if="tableData.length > 0 && !loadingReports && actualReportDownloadFileBack">
              <b-button @click="downloadReport">Descargar</b-button>
            </b-col>
          </b-row>
          <b-row v-show="tableData.length > 0 && !loadingReports">
            <b-col>
              <b-form-group label="Buscar:">
                <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="tableData.length > 0 && !loadingReports">
            <b-col>

              <b-table :per-page="byPage" :filter="search" :current-page="page" responsive class="mt-3" striped hover
                :items="tableData" small :fields="tableCols"></b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination v-model="page" :total-rows="tableData.length" :per-page="byPage"
                aria-controls="my-table"></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

    </b-overlay>
  </div>
</template>
<script>
import axios from "axios";
import Nav from "../../components/Nav.vue";
import moment from "moment"
import ReportSearchBar from "./searchBar/ReportSearchBar.vue";
// import shared from "../shared";
export default {
  name: "ReportsDate",
  components: {
    Nav, ReportSearchBar
  },

  data() {
    return {
      search: "",
      byPage: 30,
      page: 1,
      loadingReports: false,
      fileName: "reporte",
      arrayNombresSucursales: [],
      actualReportDownloadFileBack: false,
      queryDatesTickets: {
        initial_date: "",
        end_date: "",
        sucursales: []
      },
      sucursales: [],
      dateToLock: "",
      actualReport: "",
      reportOptions: [
        { value: "implosion", text: "Implosion Resumida (suma todas las sucursales)", downloadFileBack: false },
        { value: "implosionBySucursal", text: "Implosion Por Sucursal", downloadFileBack: false },
        { value: "implosionTizon", text: "Implosion En Orden Resumida (suma todas las sucursales)", downloadFileBack: false },
        { value: "popularidad", text: "Popularidad de productos resumido (suma todas las sucursales)", downloadFileBack: false },
        { value: "popularidadMesero", text: "Popularidad de productos por mesero independiente a sucursal", downloadFileBack: false },
        { value: "popularidadBySucursal", text: "Popularidad de productos por sucursal", downloadFileBack: false },
        { value: "ventaHora", text: "Venta por hora", downloadFileBack: false },
        { value: "descuentosDetalle", text: "Descuentos", downloadFileBack: false },
        { value: "cancelacionesDetalle", text: "Cancelaciones", downloadFileBack: false },
        { value: "ventaPorTipo", text: "Venta por tipo de producto", downloadFileBack: false },
        { value: "ventaPorMetodoPago", text: "Venta por metodo de pago", downloadFileBack: false },
        { value: "codigosDeDescuento", text: "Codigos de descuento", downloadFileBack: false },
        { value: "comprasDetalladas", text: "Compras Detalladas", downloadFileBack: false },
        { value: "comprasPorFecha", text: "Compras Por Fecha", downloadFileBack: true },
        { value: "comprasPorSucursal", text: "Compras Por Sucursal", downloadFileBack: true },
      ],
      tableData: [],
      tableCols: [],
      sucOptions: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getSucursals()
  },
  created() { },
  methods: {
    changeDates(p) {
      this.queryDatesTickets.sucursales = p.sucursales
      this.queryDatesTickets.end_date = moment(p.endDate).format("YYYY-MM-DD")
      this.queryDatesTickets.initial_date = moment(p.initDate).format("YYYY-MM-DD")
      this.processSucursalesIndex()
      this.getReport()
    },
    selectAllSucursales() {
      let that = this
      this.queryDatesTickets.sucursales = []
      this.sucOptions.forEach((element) => {
        that.queryDatesTickets.sucursales.push(element.value)
      })
      this.processSucursalesIndex()
    },
    processSucursalesIndex() {
      if (this.sucursales.length < 1) {
        return
      }
      console.log("processSucursalesIndex")
      let that = this
      let arr_res = that.queryDatesTickets.sucursales.map(function (sucursal_id) {
        let suc = that.sucursales.find(el => el.id == sucursal_id)
        return suc.nombre
      })
      this.arrayNombresSucursales = arr_res
      console.log("processSucursalesIndexEnd")
    },
    downloadReport() {
      if (this.actualReport == "") {
        return
      }
      let that = this
      switch (this.actualReport) {
        case "comprasPorFecha":
          axios.post("/report/compras/dates?format=pivot&file=excel", this.queryDatesTickets, {
            "responseType": "blob",
            headers: {
              'Accept': '*/*',
            }
          })
            .then(function (response) {
              console.log(response.headers)
              console.log(response)
              const blob = new Blob([response.data]);
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              const contentDisposition = response.headers['content-disposition'];
              let filename = 'downloaded_file.xlsx'; // Fallback filename in case the header is missing
              if (contentDisposition && contentDisposition.includes('filename')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
                }
              }
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
            })
            .catch(function (error) {
              console.log(error)
              that.makeToast("danger", "Error", error)
            });

          break;
          case "comprasPorSucursal":
          axios.post("/report/compras/date?format=pivot&file=excel", this.queryDatesTickets, {
            "responseType": "blob",
            headers: {
              'Accept': '*/*',
            }
          })
            .then(function (response) {
              console.log(response.headers)
              console.log(response)
              const blob = new Blob([response.data]);
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              const contentDisposition = response.headers['content-disposition'];
              let filename = 'downloaded_file.xlsx'; // Fallback filename in case the header is missing
              if (contentDisposition && contentDisposition.includes('filename')) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
                }
              }
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
            })
            .catch(function (error) {
              console.log(error)
              that.makeToast("danger", "Error", error)
            });

          break;
      }

    },
    getSucursals() {
      this.loadingReports = true
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursales = response.data
          response.data.forEach((element) => {
            that.sucOptions.push({ text: element.nombre, value: element.id });
            that.loadingReports = false
          });
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
          that.loadingReports = true

        });
    },
    setDateLock() {
      this.queryDatesTickets.end_date = ""
      this.dateToLock = moment(this.queryDatesTickets.initial_date).add(1, 'day').format('YYYY-MM-DD');
    },
    getReport() {
      if (this.actualReport == "") {
        return
      }
      this.search = ""
      this.page = 1
      // let fromDate = moment(this.queryDatesTickets.initial_date)
      let toDate = moment(this.queryDatesTickets.end_date)
      if (this.queryDatesTickets.initial_date == "" || this.queryDatesTickets.end_date == "") {
        this.$bvModal.msgBoxOk('Debes colocar fechas validas', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return
      }

      if (toDate.isBefore(this.dateToLock)) {
        this.$bvModal.msgBoxOk('La fecha final (' + moment(toDate).format('DD-MM-YYYY') + ') debe ser mayor a ' + this.dateToLock, {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return
      }
      let arrSuc = this.arrayNombresSucursales.map(function (elm) {
        return { key: "quantities." + elm, label: elm }
      })
      let arrDates = this.generateDateArray(this.queryDatesTickets.initial_date, this.queryDatesTickets.end_date).map(function (elm) {
        return { key: "quantities." + elm, label: elm }
      })
      console.log(arrDates)
      this.loadingReports = true
      this.actualReportDownloadFileBack = false
      let that = this;
      switch (this.actualReport) {
        case "popularidad":
          this.fileName = "popularidadTodasSucursales" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "clave", label: "Clave" },
            { key: "ndescription", label: "Nombre", sortable: true },
            { key: "restaurant_area_id", label: "Id Area", sortable: true },
            { key: "restaurant_area_name", label: "Area" },
            { key: "group", label: "Id Grupo", sortable: true },
            { key: "group_name", label: "Grupo" },
            { key: "product_price", label: "Precio" },
            { key: "quantity", label: "Cantidad", sortable: true },
            { key: "list_price", label: "Precio Lista" },
            { key: "total_without_discount", label: "Tot. Sin desc" },
            { key: "total_with_discount", label: "Tot. con desc" },
          ];
          axios
            .post("/report/popularidad", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);

            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "comprasDetalladas":
          this.fileName = "comprasDetalladas" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "purchase_order_id", label: "ID" },
            { key: "purchase_order_name", label: "Nombre" },
            { key: "date_planned", label: "Fecha", sortable: true },
            { key: "sucursal_nombre", label: "Sucursal", sortable: true },
            { key: "supply_name", label: "Producto", sortable: true },
            { key: "supply_code", label: "Codigo", sortable: true },
            { key: "product_quantity", label: "Cantidad", sortable: true },
            { key: "supply_unit", label: "Unidad" },
            { key: "price_unit", label: "Prec. Unit" },
            { key: "price_total", label: "Total" },
          ];
          axios
            .post("/report/compras/sucursal", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "comprasPorSucursal":
          this.fileName = "comprasPorSucursal" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          console.log(this.arrayNombresSucursales)
          this.actualReportDownloadFileBack = true
          this.tableCols = [
            { key: "supply_id", label: "ID", sortable: true },
            { key: "supply_code", label: "Codigo", sortable: true },
            { key: "supply_name", label: "Producto", sortable: true, stickyColumn: true },
            { key: "supply_unit", label: "Unidad", sortable: true },
            ...arrSuc
          ];
          axios
            .post("/report/compras/date?format=pivot", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "comprasPorFecha":
          this.fileName = "comprasPorFecha" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          console.log(this.arrayNombresSucursales)
          this.actualReportDownloadFileBack = true
          this.tableCols = [
            { key: "supply_id", label: "ID", sortable: true },
            { key: "supply_code", label: "Codigo", sortable: true },
            { key: "supply_name", label: "Producto", sortable: true, stickyColumn: true },
            { key: "supply_unit", label: "Unidad", sortable: true },
            ...arrDates
          ];
          axios
            .post("/report/compras/dates?format=pivot", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "popularidadBySucursal":
          this.fileName = "popularidadPorSucursal" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "clave", label: "Clave" },
            { key: "ndescription", label: "Nombre", sortable: true },
            { key: "restaurant_area_id", label: "Id Area", sortable: true },
            { key: "restaurant_area_name", label: "Area" },
            { key: "group", label: "Id Grupo", sortable: true },
            { key: "group_name", label: "Grupo" },
            { key: "product_price", label: "Precio" },
            { key: "quantity", label: "Cantidad", sortable: true },
            { key: "list_price", label: "Precio Lista" },
            { key: "total_without_discount", label: "Tot. Sin desc" },
            { key: "total_with_discount", label: "Tot. con desc" },
            { key: "sucursal_id", label: "ID Suc." },
            { key: "sucursal", label: "Sucursal" },

          ];
          axios
            .post("/report/popularidad/sucursal", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);

            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "popularidadMesero":
          this.fileName = "popularidadMesero" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "clave", label: "Clave" },
            { key: "ndescription", label: "Nombre", sortable: true },
            { key: "group", label: "Id Grupo", sortable: true },
            { key: "group_name", label: "Grupo" },
            { key: "restaurant_area_id", label: "Id Area", sortable: true },
            { key: "restaurant_area_name", label: "Area" },
            { key: "product_price", label: "Precio" },
            { key: "quantity", label: "Cantidad", sortable: true },
            { key: "list_price", label: "Precio Lista" },
            { key: "total_without_discount", label: "Tot. Sin desc" },
            { key: "total_with_discount", label: "Tot. con desc" },
            { key: "waiter_id", label: "ID Mesero", sortable: true },
            { key: "waiter_name", label: "Nombre Mesero", sortable: true },
          ];
          axios
            .post("/report/popularidad/waiter", this.queryDatesTickets)
            .then(function (response) {
              that.loadingReports = false
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
              that.loadingReports = false
            }).then(() => {
              that.loadingReports = false
            })
          break;

        case "ventaHora":
          this.fileName = "ventaHora" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date

          this.tableCols = [
            { key: "time", label: "Hora", sortable: true },
            { key: "lu", label: "Lu", sortable: true },
            { key: "comLu", label: "Com-Lu", sortable: true },
            { key: "ma", label: "Ma", sortable: true },
            { key: "comMa", label: "Com-Ma", sortable: true },
            { key: "mi", label: "Mi", sortable: true },
            { key: "comMi", label: "Com-Mi", sortable: true },
            { key: "ju", label: "Ju", sortable: true },
            { key: "comJu", label: "Com-Ju", sortable: true },
            { key: "vi", label: "Vi", sortable: true },
            { key: "comVi", label: "Com-Vi", sortable: true },
            { key: "sa", label: "Sa", sortable: true },
            { key: "comSa", label: "Com-Sa", sortable: true },
            { key: "do", label: "Do", sortable: true },
            { key: "comDo", label: "Com-Do", sortable: true },
          ];
          axios
            .post("/report/sales/hourly", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "cancelacionesDetalle":
          this.fileName = "cancelacionesDetalle" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date

          this.tableCols = [
            { key: "numticket", label: "#" },
            { key: "cancel_reason", label: "Razon" },
            { key: "canceled_by", label: "Usuario", sortable: true },
            { key: "ticket_open_date", label: "Apertura", sortable: true },
            { key: "ticket_cancel_date", label: "Cancelacion", sortable: true },
            { key: "sucursal_id", label: "ID Suc.", sortable: true },
            { key: "sucursal", label: "Sucursal", sortable: true },
          ];
          axios
            .post("/report/cancelations", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
              console.log(response);
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "descuentosDetalle":
          this.fileName = "descuentosDetalle" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "numticket", label: "#" },
            { key: "total_amount", label: "Subtotal", sortable: true },
            {
              key: "discount_amount",
              label: "Monto Descuento",
              sortable: true,
            },
            {
              key: "total_with_discount",
              label: "Total con descuento",
              sortable: true,
            },
            { key: "discount_type_id", label: "Id descuento", sortable: true },
            { key: "discount_name", label: "Nombre_descuento", sortable: true },
            { key: "discount_percentage", label: "Porcentaje", sortable: true },
            { key: "sucursal_id", label: "ID Suc.", sortable: true },
            { key: "sucursal", label: "Sucursal", sortable: true },
          ];
          axios
            .post("/report/discounts/sucursal", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
              console.log(response);
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "implosion":
          this.fileName = "implosion" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date

          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "code", label: "Codigo", sortable: true },
            { key: "name", label: "Nombre", sortable: true },
            { key: "total", label: "Total", sortable: true },
            { key: "unit", label: "Unidad" },
          ];
          axios
            .post("/report/implosion", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "implosionBySucursal":
          this.fileName = "implosionPorSucursal" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date

          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "code", label: "Codigo", sortable: true },
            { key: "name", label: "Nombre", sortable: true },
            { key: "total", label: "Total", sortable: true },
            { key: "unit", label: "Unidad" },
            { key: "sucursal_id", label: "ID Sucursal" },
            { key: "sucursal", label: "Sucursal" },

          ];
          axios
            .post("/report/implosion/sucursal", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "implosionTizon":
          this.fileName = "implosionTizon" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "code", label: "Codigo", sortable: true },
            { key: "name", label: "Nombre", sortable: true },
            { key: "total", label: "Total", sortable: true },
            { key: "unit", label: "Unidad" },
          ];
          axios
            .post("/tizon/report/implosion", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "ventaPorMetodoPago":
          this.fileName = "ventaPorMetodoPago" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "date", label: "Fecha" },
            { key: "sucursal", label: "Sucursal", sortable: true },
            { key: "payment_method", label: "Metodo Pago", sortable: true },
            { key: "amount", label: "Monto", sortable: true },
          ];
          axios
            .post("/report/dates/paymentmethod", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        case "codigosDeDescuento":
          this.fileName = "codigosDeDescuento" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "id", label: "ID" },
            { key: "date", label: "Fecha", sortable: true },
            { key: "total_with_discount", label: "Total c desc" },
            { key: "discount", label: "Descuento" },
            { key: "description", label: "Descripcion" },
            { key: "program", label: "Programa", sortable: true },
            { key: "sucursal_name", label: "Sucursal", sortable: true },
            { key: "code", label: "Codigo", sortable: true },
          ];
          axios
            .post("/report/dates/discountcodes", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;


        case "ventaPorTipo":
          this.fileName = "ventaPorTipo" + this.queryDatesTickets.initial_date + "_" + this.queryDatesTickets.end_date
          this.tableCols = [
            { key: "date", label: "Fecha" },
            { key: "sucursal_name", label: "Sucursal", sortable: true },
            { key: "alimentos", label: "Alimentos", sortable: true },
            { key: "bebidas", label: "Bebidas", sortable: true },
            { key: "descuentos", label: "Descuentos", sortable: true },
            { key: "total", label: "Total", sortable: true },
          ];
          if (this.queryDatesTickets.sucursales.length > 1) {
            this.$bvModal.msgBoxOk("Solamente puedes seleccionar una sucursal por consulta.")
            that.loadingReports = false
            return
          }
          axios
            .post("/report/dates/type", this.queryDatesTickets)
            .then(function (response) {
              that.tableData = response.data;
            })
            .catch(function (err) {
              console.log(err);
              that.makeToast("danger", "Error", err.response.data);
            }).then(() => {
              that.loadingReports = false
            })
          break;
        default:
          this.$bvModal.msgBoxOk('No pude encontrar ese reporte, selecciona correctamente', {
            title: 'Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          this.loadingReports = false

          break
      }
    },
    generateDateArray(startDate, endDate) {
      let dateArray = [];
      let currentDate = moment(startDate);
      let stopDate = moment(endDate);

      while (currentDate <= stopDate) {
        dateArray.push(currentDate.format("YYYY-MM-DD")); // or any desired format
        currentDate = currentDate.add(1, 'days'); // Move to the next day
      }

      return dateArray;
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
  },
};
</script>
